import React from "react";
import { Link } from "react-router-dom";
import Bubbles from "../../../components/bubbles/Bubbles";
import { Check, unCheck } from "../../../components/navbar/Navbar";
import "./Shop.scss";

window.onpopstate = function () {
    unCheck();
}

function Shop() {

    Check();

    return (<>
        <Bubbles />
        <section className="shop">
            <div className="shop--container">
                <div className='section_title' data-title='Shop' style={{ marginBottom: '2rem' }}>Coming soon..</div>
                <h2>SAILFIN FLYING FISH - WIKIPEDIA</h2>
                <p className="text" style={{ lineHeight: 1.4 }}>
                    "The sailfin flying fish (<i>Parexocoetus brachypterus</i>) is a
                    member of the flying fish family (<i>Exocoetidae</i>). As is typical
                    of other members of its family, this species has the ability to jump
                    out of the water and glide on hypertrophied fins in order to evade
                    predators..."
                </p>
                <h1 className="footer">
                    ...back to{" "}
                    <Link to="/" className="link" onClick={unCheck}>
                        home
                    </Link>
                </h1>
            </div>
        </section>
    </>);
}

export default Shop;
