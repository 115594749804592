import React from "react";
import "./Footer.scss";
import useMarkVisibleElements from "../other/useMarkVisibleElements.js";
import Bubbles from "../bubbles/Bubbles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import Logo from '../../assets/images/navbar/sailfin-logo.svg';
import { Link } from "react-router-dom";

function Footer() {
    useMarkVisibleElements(".visibility");

    return (
        <section id="footer">
            <Bubbles />
            <div className="footer_contact_box">
                <div className="footer_contact_box_title">Let's Be Friends</div>
               <div className="social_media_icon_box">
                    <a href="https://www.facebook.com/pluratosailfin/">
                        <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                    <a href="https://www.instagram.com/plurato_sailfin/">
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a href="https://www.youtube.com/channel/UCuGcsdkl9PHuIxNCdRtqTmg">
                        <FontAwesomeIcon icon={faYoutube} />
                    </a>
               </div>
            </div>
            <section className="footer_content_box">
                <div className='logo_box'>
                    <img src={Logo} alt='Plurato Logo' />
                </div>

                <div className='contact_container'>
                    <div className='contact_group'>
                        <div className='footer_group_title_box'>
                            <span className='footer_group_title_box_text'>Plurato HQ</span>
                        </div>
                        <div className='footer_group_content'>
                            <div>Ruđera Boškovića 19</div>
                            <div>21000 Split</div>
                            <div>Croatia</div>
                        </div>
                    </div>
                    <div className='contact_group'>
                        <div className='footer_group_title_box'>
                            <span className='footer_group_title_box_text'>Sales</span>
                        </div>
                        
                        <div className='footer_group_content'>
                            <div><a href="mailto:sales@plurato.com" className="footer_links">sales@plurato.com</a></div>
                            <div>+385 91 64 42 001</div>
                            <div>+385 91 25 30 023</div>
                        </div>
                    </div>
                    <div className='contact_group'>
                        <div className='footer_group_title_box'>
                            <span className='footer_group_title_box_text'>Service</span>
                        </div>
                        <div className='footer_group_content'>
                            <div>
                                <a href="mailto:service@plurato.com" className="footer_links">service@plurato.com</a>
                            </div>
                            <div>+385 91 59 37 387</div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="footer--bottom">
                <div>
                    <small>plurato © {new Date().getFullYear()}</small> 
                    <small className="footer_bull"> &nbsp; &bull; &nbsp; </small>
                    <Link className="footer_links" to="/eu">
                        <small>eu projects</small>
                    </Link>
                </div>
            </section>
        </section>
    );
}

export default Footer;
