
import About from "../../../components/about/About";
import ContactUs from "../../../components/contact/ContactUs";
import Features from "../../../components/features/Features";
import FeelTheRush from "../../../components/feelTheRush/FeelTheRush";
import Footer from "../../../components/footer/Footer";
import ForBeginnersAndProfessionals from "../../../components/ForBeginnersAndProfessionals/ForBeginnersAndProfessionals";
import Home from "../../../components/home/Home";
import Navbar from "../../../components/navbar/Navbar";
import RentingAndTeamBuilding from "../../../components/rentingAndTeamBuilding/RentingAndTeamBuilding";

function Index() {
  return (
    <>
      <Navbar />
      <Home />
      <About />
      <FeelTheRush />
      <Features />
      <ForBeginnersAndProfessionals />
      <RentingAndTeamBuilding />
      <ContactUs />
      <Footer />
    </>
  );
}

export default Index;
